import React, {useEffect, useState} from "react";

import "./login-page.scss";
import {useNavigate} from "react-router-dom";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {AxiosError} from "axios";
import {Button, Divider, Form, FormProps, Input, Typography} from "antd";
import {UserInfo} from "../../shared/model/user-info";

type FieldType = {
  email?: string;
  password?: string;
};

export const LoginPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<boolean>(false);
  const { Text } = Typography;

  useEffect(() => {
    if (window.localStorage.getItem("elap:portal:auth")) {
        loginTry();
    }
  }, []);

  const loginTry = () => {
        httpClient
            .axios()
            .get<UserInfo>(config.endPoints.getUserInfoUrl)
            .then((response) => {
                localStorage.setItem("elap:portal:user", JSON.stringify(response.data));
                window.location.replace("/");
            })
            .catch((error: AxiosError) => {
                localStorage.removeItem("elap:portal:auth");
                localStorage.removeItem("elap:portal:user");
                localStorage.removeItem("elap:portal:lastUpdateDate");
                if (error.status === 401) {
                    setLoginError(true);
                } else {
                    navigate("/error");
                }
            });
    }

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
      if (values.email?.length && values.password?.length) {
          localStorage.setItem("elap:portal:auth", btoa(`${values.email}@urfu.ru:${values.password}`));
          loginTry();
      } else {
          setLoginError(true);
      }
  };

  return (
      <div className="login-page">
        <Form
            name="basic"
            style={{ maxWidth: "inline" }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >
          <Form.Item<FieldType>
              label="E-mail:"
              labelAlign="left"
              tooltip="Название Вашей учетной записи, которая привязана к Вашему личному кабинету на сайте УрФУ"
              name="email"
              rules={[
                  { required: true, message: 'Введите почту!' },
                  { pattern: /^\w+([\.-]?\w+)*$/, message: "E-mail: неверный формат почты!" }
              ]}
          >
            <Input addonAfter="@urfu.ru" placeholder="Введите почту.." />
          </Form.Item>

          <Form.Item<FieldType>
              label="Пароль:"
              name="password"
              rules={[{ required: true, message: 'Введите пароль!' }]}
          >
            <Input.Password style={{borderRadius: "32px"}} placeholder="Введите пароль.." />
          </Form.Item>
            <Text italic><Text style={{color: "red"}}>*</Text> - Поля обязательны к заполнению</Text>
            <br />
            <br />
            {loginError ? <Text style={{color: "red"}}>Неверный логин или пароль!</Text> : null}
          <Form.Item>
            <Button type="primary" shape="round" htmlType="submit" style={{marginTop: "10px", width: "300px"}}>
              Войти
            </Button>
          </Form.Item>
        </Form>
          <Divider plain>или</Divider>
        <Button type="primary" shape="round" style={{width: "300px"}} onClick={() => navigate("/register")}>
          Зарегистрироваться
        </Button>
      </div>
  );
};
