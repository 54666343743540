import React, {useEffect, useRef, useState} from "react";

import "./mentor-messages-page.scss";
import {
    App,
    Button,
    Flex, Form,
    Input,
    InputRef, Modal, Select,
    Space,
    Table,
    TableColumnsType, TableColumnType,
    TableProps,
    Tag,
    Typography, Upload, UploadFile
} from "antd";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {FilterDropdownProps} from "antd/es/table/interface";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
// @ts-ignore
import Highlighter from "react-highlight-words";
import {IconPlus} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {UserInfo} from "../../shared/model/user-info";
import {MentorInfo} from "../../shared/model/mentor-info";
import {Message} from "../messages-page/messages-page";
import {FileList} from "../../shared/ui/file-list/file-list";
import TextArea from "antd/lib/input/TextArea";

const { Paragraph, Text } = Typography;

export const MentorMessagesPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const [mentorMessagesData, setMentorMessagesData] = useState<Message[]>([]);
    const [isOpenRequestModal, setIsOpenRequestModal] = useState<boolean>(false);
    const [isOpenCreateModal, setIsOpenCreateResponse] = useState<boolean>(false);
    const [requestContent, setRequestContent] = useState<Message | null>(null);
    const [requestStudent, setRequestStudent] = useState<UserInfo | null>(null);

    const [createResponseForm] = Form.useForm<{ response: string; filesList: UploadFile[] }>();

    useEffect(() => {
        updateMentorMessagesData();
    }, []);

    const updateMentorMessagesData = () => {
        httpClient.axios().get<Message[]>(config.endPoints.getAllMessages).then((response) => {
            setMentorMessagesData(response.data);
        });
    }

    const columns: TableProps<Message>['columns'] = [
        {
            title: 'Идентификатор вопроса',
            dataIndex: 'id',
            key: 'id',
            width: 15,
            align: 'center',
            render: (text) => <span>{parseInt(text) + 1}</span>,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Тема',
            dataIndex: 'theme',
            key: 'theme',
            align: 'center'
        },
        {
            title: 'Содержание вопроса',
            dataIndex: 'question',
            key: 'question',
            align: 'center'
        },
        {
            title: 'Статус',
            key: 'status',
            dataIndex: 'status',
            align: 'center',
            render: (_, { status }) =>
                <Tag color={status === 'Ответ готов' ? "green" : (status === 'В работе' ? "blue" : "yellow")}
                     key={status}>{status}</Tag>,
            filters: [
                {
                    text: 'Создан',
                    value: 'Создан',
                },
                {
                    text: 'В работе',
                    value: 'В работе',
                },
                {
                    text: 'Ответ готов',
                    value: 'Ответ готов',
                },
            ],
            onFilter: (value, record) => record.status.indexOf(value as string) === 0,
        },
        {
            title: 'Доступные действия',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Flex justify="center" align="center" vertical>
                    <Button type="link" onClick={() => {
                        setRequestContent(record)
                        setIsOpenRequestModal(true);
                        httpClient.axios().get<UserInfo>(config.endPoints.getStudent
                            .replace('{userId}', record.userId.toString())).then((response) => {
                            setRequestStudent(response.data);
                        })
                    }}>Детали вопроса</Button>
                    {record.status === 'Создан' ? <Button type="link" onClick={() => {
                        httpClient.axios().post(config.endPoints.changeMessageStatus
                            .replace('{messageId}', record.id.toString())).then(() => {
                            notification.success({
                                message: 'Статус успешно обновлен!'
                            });
                            updateMentorMessagesData();
                        })
                    }}>Взять в работу</Button> : (record.status === 'В работе') ? (
                        <Button type="link" onClick={() => {
                            setRequestContent(record);
                            setIsOpenCreateResponse(true);
                        }}>Дать ответ</Button>
                    ) : null}
                </Flex>
            ),
        },
    ];

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <div className="mentor-students-page">
            <div style={{textAlign: "center"}}>
                <Paragraph style={{fontSize: "18pt"}}>Вопросы</Paragraph>
            </div>
            <Table columns={columns} pagination={{ position: ["bottomCenter"], pageSize: 6 }} dataSource={mentorMessagesData} bordered />

            <Modal
                open={isOpenRequestModal}
                centered
                onCancel={() => {
                    setIsOpenRequestModal(false);
                    setRequestContent(null);
                    setRequestStudent(null);
                }}
                title="Детали вопроса"
                width={600}
                footer={[]}
            >
                <Paragraph><Text strong>ФИО студента: </Text>{requestStudent?.fullName}</Paragraph>
                <Paragraph><Text strong>E-mail студента: </Text>{requestStudent?.email}</Paragraph>
                <Paragraph><Text strong>Группа студента: </Text>{requestStudent?.groupName}</Paragraph>
                <Paragraph><Text strong>Тема: </Text>{requestContent?.theme}</Paragraph>
                <Paragraph><Text strong>Вопрос: </Text>{requestContent?.question}</Paragraph>
                <Paragraph><Text strong>Файлы вопроса: </Text></Paragraph>
                <FileList files={requestContent?.questionFiles ? requestContent?.questionFiles : []} />
                {requestContent?.response ? <Paragraph><Text strong>Ответ: </Text>{requestContent?.response}</Paragraph> : null}
                {requestContent?.response ?
                    <>
                        <Paragraph><Text strong>Файлы ответа: </Text></Paragraph>
                        <FileList files={requestContent?.responseFiles ? requestContent?.responseFiles : []} />
                    </> : null}
            </Modal>

            <Modal
                open={isOpenCreateModal}
                centered
                onCancel={() => {
                    setIsOpenCreateResponse(false);
                    createResponseForm.resetFields(["response", "filesList"]);
                }}
                onOk={() => {
                    if (requestContent) {
                        const createResponse = createResponseForm.getFieldsValue();
                        const responseFiles = createResponse.filesList?.length ? createResponse.filesList
                            .map((uploadFile) => uploadFile.originFileObj as File) : [];
                        const formData = new FormData();
                        formData.append("messageId", requestContent.id.toString());
                        formData.append("response", createResponse.response);
                        responseFiles.forEach((file) => {
                            formData.append("responseFiles", file);
                        });

                        httpClient.axios().post(config.endPoints.createMessageResponse, formData)
                            .then((response) => {
                            notification.success({
                                message: 'Ваш вопрос успешно отправлен!'
                            })
                            setIsOpenCreateResponse(false);
                            createResponseForm.resetFields(["response", "filesList"]);
                            updateMentorMessagesData();
                        }).catch(() => {
                            navigate('/error')
                        });
                    }
                }}
                title="Дать ответ"
                width={600}
                okText="Отправить"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Form
                    form={createResponseForm}
                    layout="vertical"
                >
                    <Form.Item name="response" label="Ответ:" required rules={[
                        { required: true, message: 'Ответ: обязательное поле!' },
                        { type: 'string', min: 10, message: 'Ответ: длина должна быть больше или равна 10 символам!' },
                    ]}>
                        <TextArea placeholder="Опишите ваш ответ" rows={6} style={{borderRadius: "14px"}}
                                  maxLength={1000} count={{max: 1000, show: true}} />
                    </Form.Item>
                    <Form.Item name="filesList" label="Файлы:" getValueFromEvent={normFile}>
                        <Upload listType="picture-card" maxCount={5} beforeUpload={() => false}>
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Прикрепить файл</div>
                            </button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
};
