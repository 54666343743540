import React, {useEffect, useState} from "react";
import {Card, Col, Collapse, CollapseProps, Flex, Image, Row, Typography} from 'antd';
import HeroImg from 'hero.webp';

import "./main-page.scss";
import {Section} from "../../shared/ui/section/section";
import {Advertisement} from "../../shared/model/advertisement";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {UserInfo} from "../../shared/model/user-info";
import Logo from "../../logo.svg";
import LogoUrfu from "../../logo_urfu.svg";

const {Paragraph } = Typography;

const questionsData: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Вопрос 1',
    children: <p>В разработке.</p>,
  },
  {
    key: '2',
    label: 'Вопрос 2',
    children: <p>В разработке.</p>,
  },
  {
    key: '3',
    label: 'Вопрос 3',
    children: <p>В разработке.</p>,
  },
];

export const MainPage = (): JSX.Element => {
  const [lastAdvertisementsData, setLastAdvertisementsData] = useState<Advertisement[]>([]);

  useEffect(() => {
    httpClient.axios().get<Advertisement[]>(config.endPoints.getLastAdvertisementsUrl).then((response) => {
      setLastAdvertisementsData(response.data);
    }).catch(() => {
      setLastAdvertisementsData([]);
    });
  }, []);

  const getAdvertisements = () => {
    const result: JSX.Element[] = [];

    if (lastAdvertisementsData.length) {
      lastAdvertisementsData.forEach((item) => {
        result.push(
            <Col xs={24} sm={24} md={24} lg={24}
                 xl={lastAdvertisementsData.length === 1 ? 24 : (lastAdvertisementsData.length === 2 ? 12 : 8)}
                 xxl={lastAdvertisementsData.length === 1 ? 24 : (lastAdvertisementsData.length === 2 ? 12 : 8)}>
              <Card style={{backgroundColor: "#d8b2ed", height: "100%"}} bordered={false}>
                <Paragraph style={{fontSize: "12pt", fontWeight: "bold"}}>
                  {item.title}
                </Paragraph>
                <Paragraph>
                  {item.text}
                </Paragraph>
              </Card>
            </Col>
        )
      });
    } else {
      result.push(
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Card style={{backgroundColor: "#d8b2ed", height: "100%", display: "flex",
              justifyContent: "center", alignItems: "center"}} bordered={false}>
              <Paragraph style={{fontSize: "12pt", fontWeight: "bold", marginBottom: "0"}}>
                Пока нет никаких объявлений
              </Paragraph>
            </Card>
          </Col>
      )
    }

    return result;
  };

  return <div className="main-page">
    <div className="hero-section">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={13} className="hero-text">
          <Flex justify="center" align="center" vertical gap={15}>
            <Paragraph className="row-1">
              Электронная
            </Paragraph>
            <Paragraph className="row-2">
              ЛАБОРАТОРИЯ
            </Paragraph>
            <Paragraph className="row-3" italic>
              академического
            </Paragraph>
            <Paragraph className="row-4" italic>
              письма
            </Paragraph>
          </Flex>
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={2} />
        <Col xs={0} sm={0} md={0} lg={0} xl={9} xxl={9}>
          <Flex align="center" justify="center">
            <Image
                preview={false}
                src={HeroImg}
                className="hero-img"
            />
          </Flex>
        </Col>
      </Row>
    </div>
    <Section title="Объявления" additionalUrl="/advertisements" additionalText="Посмотреть все">
      <Row gutter={[16, 16]}>
        {getAdvertisements()}
      </Row>
    </Section>
    <br />
    <br />
    <br />
    <Section title="FAQ">
      <Collapse size="middle" accordion items={questionsData} />
    </Section>
    <br />
    <br />
    <br />
    <div className="footer">
      <Flex justify="center" align="center" gap={20}>
        <img src={Logo} height={70} width={120} />
        <span>© 2024</span>
        <img src={LogoUrfu} height={70} width={120} />
      </Flex>
    </div>
  </div>;
};
