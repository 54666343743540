import React from 'react';
import './image-block.scss';
import { Image } from 'antd';

type ImageBlockProps = {
    srcUrl: string;
};

export const ImageBlock = (props: ImageBlockProps): JSX.Element => {
    return (
        <Image
            width={300}
            className="image-block"
            src={props.srcUrl}
        />
    );
}
