import React from "react";

import "./register-page.scss";
import {useNavigate} from "react-router-dom";
import {App, Button, Checkbox, Divider, Form, FormProps, Input, Typography} from "antd";
import {saveAs} from "file-saver";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";

type FieldType = {
    firstName?: string;
    lastName?: string;
    patronymic?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    confirmOPD?:boolean;
};

export const RegisterPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { notification } = App.useApp();
    const { Text } = Typography;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        httpClient.axios().post(config.endPoints.registerUser, values).then((response) => {
           if (response.status === 200) {
               notification.success({
                   message: 'Ваша регистрация прошла успешно!'
               });
               setTimeout(() => {
                   navigate('/login');
               }, 4000);
           } else {
               navigate('/error');
           }
        }).catch(() => {
            navigate('/error')
        });
    };

    return (
        <div className="register-page">
            <Form
                name="basic"
                style={{maxWidth: "inline"}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item<FieldType>
                    label="Фамилия:"
                    labelAlign="left"
                    name="lastName"
                    rules={[
                        { required: true, message: 'Фамилия: обязательное поле!' },
                        { pattern: /^[а-яА-ЯёЁ]+$/, message: 'Фамилия: неверный формат поля!' }
                    ]}
                >
                    <Input placeholder="Введите фамилию.." style={{borderRadius: "32px"}}/>
                </Form.Item>
                <Form.Item<FieldType>
                    label="Имя:"
                    labelAlign="left"
                    name="firstName"
                    rules={[
                        { required: true, message: 'Имя: обязательное поле!' },
                        { pattern: /^[а-яА-ЯёЁ]+$/, message: 'Имя: неверный формат поля!' }
                    ]}
                >
                    <Input placeholder="Введите имя.." style={{borderRadius: "32px"}}/>
                </Form.Item>
                <Form.Item<FieldType>
                    label="Отчество:"
                    labelAlign="left"
                    name="patronymic"
                    rules={[
                        { pattern: /^[а-яА-ЯёЁ]+$/, message: 'Отчество: неверный формат поля!' }
                    ]}
                >
                    <Input placeholder="Введите отчество (если оно есть).." style={{borderRadius: "32px"}}/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="E-mail:"
                    labelAlign="left"
                    tooltip="Название Вашей учетной записи, которая привязана к Вашему личному кабинету на сайте УрФУ"
                    name="email"
                    rules={[
                        { required: true, message: 'E-mail: обязательное поле!' },
                        { pattern: /^\w+([\.-]?\w+)*$/, message: "E-mail: неверный формат почты!" }
                    ]}
                >
                    <Input addonAfter="@urfu.ru" placeholder="Введите почту.."/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="Пароль:"
                    tooltip="Пароль должен содержать минимум 8 символов"
                    name="password"
                    rules={[
                        { required: true, message: 'Пароль: обязательное поле!' },
                        { type: 'string', min: 8, message: 'Пароль: длина должна быть больше или равна 8 символам!' },
                    ]}
                >
                    <Input.Password style={{borderRadius: "32px"}} placeholder="Введите пароль.."/>
                </Form.Item>

                <Form.Item<FieldType>
                    label="Повторите пароль:"
                    name="confirmPassword"
                    rules={[
                        {required: true, message: 'Введите пароль повторно!'},
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли не совпадают!'));
                            },
                        })]}
                >
                    <Input.Password style={{borderRadius: "32px"}} placeholder="Введите пароль повторно.."/>
                </Form.Item>

                <Form.Item
                    name="confirmOPD"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Соглашение должно быть принято!')),
                        },
                    ]}
                >
                    <Checkbox>
                        <Text style={{color: "red"}}>*</Text> Я согласен на <a onClick={() => {
                            saveAs(new File([], "Положение об обработке персональных данных.pdf"))
                        }}>обработку персональных данных</a>.
                    </Checkbox>
                </Form.Item>

                <Text italic><Text style={{color: "red"}}>*</Text> - Поля обязательны к заполнению</Text>
                <br />
                <br />
                <Form.Item>
                    <Button type="primary" shape="round" htmlType="submit" style={{marginTop: "10px", width: "300px"}}>
                        Зарегистрироваться
                    </Button>
                </Form.Item>
            </Form>
            <Divider plain>или</Divider>
            <Button type="primary" shape="round" style={{width: "300px"}} onClick={() => navigate("/login")}>
                Войти
            </Button>
        </div>
    );
};
