import React, {useEffect, useRef, useState} from "react";

import "./mentor-tasks-page.scss";
import {
    App,
    Button,
    Card,
    Empty,
    Flex, Form, Input,
    List,
    Modal, Radio, Select,
    Table,
    TableProps,
    Tag,
    Typography, Upload, UploadFile
} from "antd";
import {IconClockHour5, IconPlus, IconSchool, IconUser} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {UserInfo} from "../../shared/model/user-info";
import {FileList} from "../../shared/ui/file-list/file-list";
import TextArea from "antd/lib/input/TextArea";
import {TextBlock} from "../../shared/ui/blocks/text-block/text-block";
import moment from "moment";
import {httpClient} from "../../shared/api/http-client";
import {config} from "../../shared/config";
import {PlusOutlined} from "@ant-design/icons";
import {Editor} from "@tinymce/tinymce-react";

const { Paragraph, Text } = Typography;

enum TaskCommentUserType {
    USER = 'user',
    MENTOR = 'mentor'
}

type TaskComment = {
    maintainerUserType: TaskCommentUserType;
    message: string;
    files?: string[];
    date: Date;
}

enum TaskType {
    REVIEW = 'review',
    ESSAY = 'essay'
}

enum TaskStatus {
    CREATED = 'created',
    REVIEW = 'review',
    REWORK = 'rework',
    COMPLETE = 'complete'
}

type Task = {
    type: TaskType,
    id: number;
    student: UserInfo;
    mentor: UserInfo;
    text: string;
    taskFiles: string[];
    status: TaskStatus;
    comments: TaskComment[];
    responseText?: string;
    responseFiles?: string[];
}

const testTasksData: Task[] = [
    {
        type: TaskType.REVIEW,
        id: 0,
        student: {
            id: 0,
            firstName: '',
            lastName: '',
            patronymic: '',
            fullName: 'Тестовый юзер',
            email: 'email@urfu.test',
            groupName: 'Тестовая группа',
            authorities: [],
            isActive: true
        },
        mentor: {
            id: 0,
            firstName: '',
            lastName: '',
            patronymic: '',
            fullName: 'Тестовый ментор',
            email: 'mentor@urfu.test',
            authorities: [],
            isActive: true
        },
        text: 'Описание тестового задания',
        taskFiles: [],
        status: TaskStatus.CREATED,
        comments: [
            {
                maintainerUserType: TaskCommentUserType.MENTOR,
                message: 'Выполните задание внимательно!',
                date: new Date()
            },
            {
                maintainerUserType: TaskCommentUserType.MENTOR,
                message: 'Выполните задание внимательно!!!!!!!',
                date: new Date()
            },
            {
                maintainerUserType: TaskCommentUserType.USER,
                message: 'Да я и так внимательно делал!',
                date: new Date()
            }
        ]
    },
    {
        type: TaskType.ESSAY,
        id: 1,
        student: {
            id: 1,
            firstName: '',
            lastName: '',
            patronymic: '',
            fullName: 'Тестовый юзер',
            email: 'email@urfu.test',
            groupName: 'Тестовая группа',
            authorities: [],
            isActive: true
        },
        mentor: {
            id: 0,
            firstName: '',
            lastName: '',
            patronymic: '',
            fullName: 'Тестовый ментор',
            email: 'mentor@urfu.test',
            authorities: [],
            isActive: true
        },
        text: 'Описание тестового задания 2',
        taskFiles: [],
        status: TaskStatus.REWORK,
        comments: [
            {
                maintainerUserType: TaskCommentUserType.MENTOR,
                message: 'Выполните задание внимательно!',
                date: new Date()
            }
        ]
    }
];

export const MentorTasksPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { notification } = App.useApp();

    const editorRef = useRef<any>();

    const [tasksData, setTasksData] = useState<Task[]>([]);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [newCommentText, setNewCommentText] = useState<string>('');
    const [studentsData, setStudentsData] = useState<UserInfo[]>([]);
    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState<boolean>(false);
    const [isOpenReviewModal, setIsOpenReviewModal] = useState<boolean>(false);
    const [isOpenNewTaskModal, setIsOpenNewTaskModal] = useState<boolean>(false);
    const [isOpenEditTaskModal, setIsOpenEditTaskModal] = useState<boolean>(false);
    const [isOpenDeleteTaskModal, setIsOpenDeleteTaskModal] = useState<boolean>(false);

    const [newTaskForm] = Form.useForm<{ studentId: number, type: string, taskFiles: UploadFile[] }>();
    const [editTaskForm] = Form.useForm<{ studentId: number, type: string, taskFiles: UploadFile[] }>();

    useEffect(() => {
        updateTasksAndUsersData();
    }, []);

    const updateTasksAndUsersData = () => {
        httpClient.axios().get<Task[]>(config.endPoints.getAllTasks).then((response) => {
            setTasksData(response.data);
        });
        setTasksData(testTasksData);
        httpClient.axios().get<UserInfo[]>(config.endPoints.getAllStudents).then((response) => {
            setStudentsData(response.data);
        });
    }

    const tasksTableProps: TableProps<Task> = {
        bordered: true,
        size: "middle",
        title: () => {return(<Button onClick={() => {
            setIsOpenNewTaskModal(true);
        }} icon={<IconPlus />}>Добавить задание</Button>)},
        showHeader: true
    };

    const tasksTableColumns: TableProps<Task>['columns'] = [
        {
            title: 'Идентификатор задания',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            align: 'center',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Тип задания',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            align: 'center',
            render: (_, record) => record.type === TaskType.REVIEW ?
                (<span>Рецензия</span>) :
                (<span>Эссе</span>)
        },
        {
            title: 'ФИО студента',
            dataIndex: 'student',
            key: 'student',
            width: 450,
            align: 'center',
            render: (_, record) => (<Text>
                {record.student.fullName}
            </Text>)
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_, record) => getStatusRender(record.status),
        },
        {
            title: 'Доступные действия',
            key: 'action',
            align: 'center',
            width: 100,
            render: (_, record) => (
                <Flex justify="center" align="center" vertical>
                    <Button type="link" onClick={() => {
                        setSelectedTask(record);
                        setIsOpenDetailsModal(true);}}>
                        Детали задания
                    </Button>
                    {record.status !== TaskStatus.CREATED && record.status !== TaskStatus.COMPLETE ? (
                        <Button type="link" onClick={() => {
                            setSelectedTask(record);
                            setIsOpenReviewModal(true);}}>
                            Проверить
                        </Button>
                    ) : null}
                    {record.status === TaskStatus.CREATED ? (
                        <>
                            <Button type="link" onClick={() => {
                                setSelectedTask(record);
                                setIsOpenEditTaskModal(true);}}>
                                Изменить
                            </Button>
                            <Button type="link" onClick={() => {
                                setSelectedTask(record);
                                setIsOpenDeleteTaskModal(true);}}>
                                Удалить
                            </Button>
                        </>
                    ) : null}
                </Flex>
            ),
        },
    ];

    const getStatusRender = (status: TaskStatus): JSX.Element => {
        switch (status) {
            case (TaskStatus.CREATED): {
                return (
                    <Tag color="yellow">Создано</Tag>
                )
            }
            case (TaskStatus.REVIEW): {
                return (
                    <Tag color="blue">На проверке</Tag>
                )
            }
            case (TaskStatus.REWORK): {
                return (
                    <Tag color="orange">На доработку</Tag>
                )
            }
            case (TaskStatus.COMPLETE): {
                return (
                    <Tag color="green">Выполнено</Tag>
                )
            }
        }
    }

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <div className="mentor-tasks-page">
            <div style={{textAlign: "center"}}>
                <Paragraph style={{fontSize: "18pt"}}>Задания</Paragraph>
            </div>
            <Table {...tasksTableProps} columns={tasksTableColumns} pagination={{ position: ["bottomCenter"], pageSize: 6 }}
                   dataSource={tasksData} bordered />
            <br />

            <Modal
                open={isOpenDetailsModal}
                centered
                onCancel={() => {
                    setIsOpenDetailsModal(false);
                }}
                footer={[]}
                title="Детали задания"
                width={800}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                {selectedTask ? (
                    <Flex vertical gap={10}>
                        <Paragraph><Text strong style={{marginRight: '5px'}}
                        >Статус задания: </Text>{getStatusRender(selectedTask.status)}</Paragraph>
                        <Paragraph><Text strong>Тип задания: </Text>{selectedTask.type === TaskType.ESSAY ? 'Эссе' : 'Рецензия'}</Paragraph>
                        <Paragraph><Text strong>ФИО студента: </Text>{selectedTask.student.fullName}</Paragraph>
                        <Paragraph><Text strong>E-mail студента: </Text>{selectedTask.student.email}</Paragraph>
                        <Paragraph><Text strong>Группа студента: </Text>{selectedTask.student.groupName}</Paragraph>
                        <Paragraph><Text strong>Описание задания: </Text></Paragraph>
                        <Card>
                            <TextBlock data={selectedTask.text} />
                        </Card>
                        <Paragraph><Text strong>Файлы задания: </Text></Paragraph>
                        <FileList files={selectedTask.taskFiles ? selectedTask.taskFiles : []} />
                        <Paragraph><Text strong>Коментрии: </Text></Paragraph>
                        <Card>
                            {selectedTask.comments.length ? (
                                <List
                                    itemLayout="horizontal"
                                    dataSource={selectedTask.comments}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item.maintainerUserType === TaskCommentUserType.USER ?
                                                    <IconUser /> : <IconSchool />}
                                                title={item.maintainerUserType === TaskCommentUserType.USER ?
                                                        selectedTask?.student.fullName : selectedTask?.mentor.fullName}
                                                description={
                                                   <Flex vertical justify="center" gap={5}>
                                                       <Flex gap={3} align="center">
                                                           <IconClockHour5 size={18} />
                                                           <Text italic>{moment(item.date).format('LLL')}</Text>
                                                       </Flex>
                                                       <Text>{item.message}</Text>
                                                       {item.files?.length ? <FileList files={item.files} /> : null}
                                                   </Flex>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            ) : <Empty description="Коментарии отсутвуют" />}
                        </Card>
                        {selectedTask.responseText ? <Paragraph><Text strong>Ответ: </Text></Paragraph> : null}
                        {selectedTask.responseText ? <TextBlock data={selectedTask.responseText} /> : null}
                        {selectedTask.responseText ?
                            <>
                                <Paragraph><Text strong>Файлы ответа: </Text></Paragraph>
                                <FileList files={selectedTask.responseFiles ? selectedTask.responseFiles : []} />
                            </> : null}
                    </Flex>
                ) : null}
            </Modal>

            <Modal
                open={isOpenReviewModal}
                centered
                onCancel={() => {
                    setIsOpenReviewModal(false);
                }}
                footer={[
                    <Button key="back" type="text" shape="round" onClick={() => {
                        setIsOpenReviewModal(false);
                    }}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="text" shape="round" onClick={() => {
                        if (selectedTask) {
                            httpClient.axios().post(config.endPoints.setTaskStatusRework
                                .replace('{taskId}', selectedTask.id.toString()), {
                                commentText: newCommentText
                            }).then(() => {
                                notification.success({
                                    message: 'Задание упешно отправлено на доработку!'
                                });
                                setIsOpenReviewModal(false);
                                setNewCommentText('');
                                updateTasksAndUsersData();
                            })
                        }
                    }}>
                        Отправить на доработку
                    </Button>,
                    <Button key="submit" type="primary" shape="round" onClick={() => {
                        if (selectedTask) {
                            httpClient.axios().post(config.endPoints.setTaskStatusComplete
                                .replace('{taskId}', selectedTask.id.toString()), {
                                commentText: newCommentText
                            }).then(() => {
                                notification.success({
                                    message: 'Задание успешно принято!'
                                });
                                setIsOpenReviewModal(false);
                                setNewCommentText('');
                                updateTasksAndUsersData();
                            })
                        }
                    }}>
                        Принять
                    </Button>
                ]}
                title="Проверка задания"
                width={800}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                {selectedTask ? (
                    <Flex vertical gap={5}>
                        <Paragraph><Text strong>ФИО студента: </Text>{selectedTask.student.fullName}</Paragraph>
                        <Paragraph><Text strong>Коментрии: </Text></Paragraph>
                        <Card>
                            {selectedTask.comments.length ? (
                                <List
                                    itemLayout="horizontal"
                                    dataSource={selectedTask.comments}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item.maintainerUserType === TaskCommentUserType.USER ?
                                                    <IconUser /> : <IconSchool />}
                                                title={item.maintainerUserType === TaskCommentUserType.USER ?
                                                    selectedTask?.student.fullName : selectedTask?.mentor.fullName}
                                                description={
                                                    <Flex vertical justify="center" gap={5}>
                                                        <Flex gap={3} align="center">
                                                            <IconClockHour5 size={18} />
                                                            <Text italic>{moment(item.date).format('LLL')}</Text>
                                                        </Flex>
                                                        <Text>{item.message}</Text>
                                                        {item.files?.length ? <FileList files={item.files} /> : null}
                                                    </Flex>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            ) : <Empty description="Коментарии отсутвуют" />}
                        </Card>
                        {selectedTask.responseText ? <Paragraph><Text strong>Ответ: </Text></Paragraph> : null}
                        {selectedTask.responseText ? <TextBlock data={selectedTask.responseText} /> : null}
                        {selectedTask.responseFiles?.length ? <FileList files={selectedTask.responseFiles} /> : null}
                        <br />
                        <Paragraph><Text strong>Оставить комментарий: </Text></Paragraph>
                        <TextArea placeholder="Введите ваш комментарий.." value={newCommentText} onChange={(e) => {
                            setNewCommentText(e.target.value);
                        }} />
                    </Flex>
                ) : null}
            </Modal>

            <Modal
                open={isOpenNewTaskModal}
                centered
                onCancel={() => {
                    setIsOpenNewTaskModal(false);
                    newTaskForm.resetFields(["studentId", "type", "taskFiles"]);
                }}
                onOk={() => {
                    newTaskForm.validateFields().then((fields) => {
                        const taskFiles = fields.taskFiles?.length ? fields.taskFiles
                            .map((uploadFile) => uploadFile.originFileObj as File) : [];
                        const formData = new FormData();
                        formData.append("studentId", fields.studentId.toString());
                        formData.append("type", fields.type);
                        formData.append("text", editorRef.current.getContent());
                        taskFiles.forEach((file) => {
                            formData.append("taskFiles", file);
                        });

                        httpClient.axios().post(config.endPoints.addTask, formData)
                            .then((response) => {
                                notification.success({
                                    message: 'Задание успешно создано!'
                                })
                                setIsOpenNewTaskModal(false);
                                newTaskForm.resetFields(["studentId", "type", "taskFiles"]);
                                updateTasksAndUsersData();
                            }).catch(() => {
                            navigate('/error')
                        });
                    }).catch(() => {});
                }}
                title="Добавить задание"
                width={800}
                okText="Добавить"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Form
                    form={newTaskForm}
                    layout="vertical"
                >
                    <Form.Item name="type" label="Тип задания:" initialValue="review" required rules={[
                        { required: true, message: 'Тип задания: обязательное поле!' },
                    ]}>
                        <Radio.Group>
                            <Radio value="review">Рецензия</Radio>
                            <Radio value="essay">Эссе</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="studentId" label="Студент:" required rules={[
                        { required: true, message: 'Студент: обязательное поле!' },
                    ]}>
                        <Select options={studentsData.map((student) =>
                        {return {value: student.id, label: student.fullName}})} />
                    </Form.Item>
                    <Form.Item label="Текст задания:">
                        <Editor
                            tinymceScriptSrc='/tinymce/tinymce.min.js'
                            licenseKey='gpl'
                            onInit={(_evt, editor) => editorRef.current = editor}
                            initialValue=''
                            init={{
                                language: 'ru',
                                height: 400,
                                branding: false,
                                elementpath: false,
                                help_accessibility: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'charmap', 'preview', 'link',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'table', 'help', 'wordcount'
                                ],
                                menu: {
                                    file: { title: 'File', items: '' },
                                    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                                    view: { title: 'View', items: 'visualblocks | spellchecker | preview fullscreen' },
                                    insert: { title: 'Insert', items: 'inserttable | link | hr | anchor' },
                                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
                                    tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
                                    table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                                    help: { title: 'Help', items: 'help' }
                                },
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic underline strikethrough | link | forecolor backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat'
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Файлы:" name="taskFiles" getValueFromEvent={normFile}>
                        <Upload listType="picture-card" maxCount={5} beforeUpload={() => false}>
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Прикрепить файл</div>
                            </button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={isOpenEditTaskModal}
                centered
                onCancel={() => {
                    setIsOpenEditTaskModal(false);
                    editTaskForm.resetFields(["studentId", "type", "taskFiles"]);
                }}
                onOk={() => {
                    if (selectedTask) {
                        editTaskForm.validateFields().then((fields) => {
                            const taskFiles = fields.taskFiles?.length ? fields.taskFiles
                                .map((uploadFile) => uploadFile.originFileObj as File) : [];
                            const formData = new FormData();
                            formData.append("studentId", fields.studentId.toString());
                            formData.append("type", fields.type);
                            formData.append("text", editorRef.current.getContent());
                            taskFiles.forEach((file) => {
                                formData.append("taskFiles", file);
                            });

                            httpClient.axios().put(config.endPoints.editTask
                                .replace('{taskId}', selectedTask.id.toString()), formData)
                                .then((response) => {
                                    notification.success({
                                        message: 'Задание успешно сохранено!'
                                    })
                                    setIsOpenEditTaskModal(false);
                                    editTaskForm.resetFields(["studentId", "type", "taskFiles"]);
                                    updateTasksAndUsersData();
                                }).catch(() => {
                                navigate('/error')
                            });
                        }).catch(() => {});
                    }
                }}
                title="Изменить задание"
                width={800}
                okText="Сохранить"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                <Form
                    form={editTaskForm}
                    layout="vertical"
                >
                    <Form.Item name="type" label="Тип задания:" initialValue={selectedTask?.type} required rules={[
                        { required: true, message: 'Тип задания: обязательное поле!' },
                    ]}>
                        <Radio.Group>
                            <Radio value="review">Рецензия</Radio>
                            <Radio value="essay">Эссе</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="studentId" label="Студент:" initialValue={selectedTask?.student.id} required rules={[
                        { required: true, message: 'Студент: обязательное поле!' },
                    ]}>
                        <Select options={studentsData.map((student) =>
                        {return {value: student.id, label: student.fullName}})} />
                    </Form.Item>
                    <Form.Item label="Текст задания:">
                        <Editor
                            tinymceScriptSrc='/tinymce/tinymce.min.js'
                            licenseKey='gpl'
                            onInit={(_evt, editor) => editorRef.current = editor}
                            initialValue={selectedTask?.text}
                            init={{
                                language: 'ru',
                                height: 400,
                                branding: false,
                                elementpath: false,
                                help_accessibility: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'charmap', 'preview', 'link',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'table', 'help', 'wordcount'
                                ],
                                menu: {
                                    file: { title: 'File', items: '' },
                                    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                                    view: { title: 'View', items: 'visualblocks | spellchecker | preview fullscreen' },
                                    insert: { title: 'Insert', items: 'inserttable | link | hr | anchor' },
                                    format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
                                    tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
                                    table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                                    help: { title: 'Help', items: 'help' }
                                },
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic underline strikethrough | link | forecolor backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat'
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Файлы:" name="taskFiles" initialValue={selectedTask?.taskFiles} getValueFromEvent={normFile}>
                        <Upload listType="picture-card" maxCount={5} beforeUpload={() => false}>
                            <button style={{ border: 0, background: 'none' }} type="button">
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Прикрепить файл</div>
                            </button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={isOpenDeleteTaskModal}
                centered
                onCancel={() => {
                    setIsOpenDeleteTaskModal(false);
                }}
                onOk={() => {
                    if (selectedTask) {
                        httpClient.axios().delete(config.endPoints.deleteTask
                            .replace('{taskId}', selectedTask.id.toString() as string)).then(() => {
                            notification.success({
                                message: 'Задание успешно удалено!'
                            })
                            setIsOpenDeleteTaskModal(false);
                            updateTasksAndUsersData();
                        }).catch(() => {
                            navigate('/error')
                        });
                    }
                }}
                title="Удаление задания"
                width={600}
                okText="Удалить"
                okButtonProps={{shape: "round"}}
                cancelButtonProps={{shape: "round", type: "text"}}
            >
                Вы действитель хотите удалить данное задание?
            </Modal>
        </div>
    )
};
