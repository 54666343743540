import React, {useContext} from "react";

import "./account-page.scss";
import {AuthContext} from "../../shared/ui/auth-context/auth-context";
import {Button, Flex, Form, Input, Spin, Typography} from "antd";
import {IconChecklist, IconMessageCircleQuestion} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";
import {UserAuthorityType} from "../../shared/model/user-authority";

const { Paragraph } = Typography;

export const AccountPage = (): JSX.Element => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const userFullName = `${authContext?.userInfo.lastName} `
        + `${authContext?.userInfo.firstName}${authContext?.userInfo.patronymic ?
            ' ' + authContext.userInfo.patronymic : ''}`;

    const getAccountByRole = (): JSX.Element => {
        if (authContext) {
            if (authContext.userInfo.authorities?.filter((authorityInfo) =>
                authorityInfo.authority === UserAuthorityType.ADMIN).length) {
                return (
                    <>
                        <div style={{textAlign: "center"}}>
                            <Paragraph style={{fontSize: "18pt"}}>Личный кабинет</Paragraph>
                        </div>
                        <Flex style={{height: '100%'}} justify="center" align="center" vertical>
                            <Form
                                name="basic"
                                style={{ maxWidth: "inline" }}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="ФИО:"
                                    labelAlign="left"
                                >
                                    <Input value={userFullName} disabled style={{borderRadius: "32px", width: "650px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="Роль:"
                                    labelAlign="left"
                                >
                                    <Input value="Администратор" disabled style={{borderRadius: "32px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="E-mail:"
                                    labelAlign="left"
                                >
                                    <Input value={authContext.userInfo.email} disabled style={{borderRadius: "32px"}} />
                                </Form.Item>
                            </Form>
                        </Flex>
                    </>
                );
            } else if (authContext.userInfo.authorities?.filter((authorityInfo) =>
                authorityInfo.authority === UserAuthorityType.MENTOR).length) {
                return (
                    <>
                        <div style={{textAlign: "center"}}>
                            <Paragraph style={{fontSize: "18pt"}}>Личный кабинет</Paragraph>
                        </div>
                        <Flex style={{height: '100%'}} justify="center" align="center" vertical>
                            <Form
                                name="basic"
                                style={{ maxWidth: "inline" }}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="ФИО:"
                                    labelAlign="left"
                                >
                                    <Input value={userFullName} disabled style={{borderRadius: "32px", width: "650px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="Роль:"
                                    labelAlign="left"
                                >
                                    <Input value="Ментор" disabled style={{borderRadius: "32px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="E-mail:"
                                    labelAlign="left"
                                >
                                    <Input value={authContext.userInfo.email} disabled style={{borderRadius: "32px"}} />
                                </Form.Item>
                            </Form>
                            <Button type="primary" shape="round" size="large" onClick={() => navigate("/")}>
                                Сменить пароль
                            </Button>
                        </Flex>
                    </>
                );
            } else {
                return (
                    <>
                        <div style={{textAlign: "center"}}>
                            <Paragraph style={{fontSize: "18pt"}}>Личный кабинет</Paragraph>
                        </div>
                        <Flex style={{height: '100%'}} justify="center" align="center" vertical>
                            <Form
                                name="basic"
                                style={{ maxWidth: "inline" }}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="ФИО:"
                                    labelAlign="left"
                                >
                                    <Input value={userFullName} disabled style={{borderRadius: "32px", width: "650px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="Группа:"
                                    labelAlign="left"
                                >
                                    <Input value={authContext.userInfo.groupName} disabled style={{borderRadius: "32px"}} />
                                </Form.Item>

                                <Form.Item
                                    label="Ваш ментор:"
                                    labelAlign="left"
                                >
                                    <Input value={authContext.userInfo.mentor?.fullName} disabled style={{borderRadius: "32px"}} />
                                </Form.Item>
                            </Form>
                            <div className="actions-block">
                                <Button type="primary" shape="round" icon={<IconMessageCircleQuestion />} size="large"
                                        onClick={() => navigate("/messages")}>
                                    Задать вопрос ментору
                                </Button>
                                <Button type="primary" shape="round" icon={<IconChecklist />} size="large"
                                        onClick={() => navigate("/tasks")}>
                                    Посмотреть текущие задания
                                </Button>
                            </div>
                        </Flex>
                    </>
                )
            }
        }
        return (<></>);
    };

    return (
        <div className="account-page">
            {authContext ? getAccountByRole() :
                <Spin tip="Загрузка" size="large" spinning={!authContext} />}
        </div>
    )
};
